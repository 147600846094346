/**
 * = Modals
 */

.modal{
    &.static-example{
        position: relative;
        display: block;
    }
}

.modal-content {
    border: 0;
    @if $enable-rounded {
        border-radius: $modal-content-border-radius;
    } @else {
        border-radius: 0;
    }
}

.modal-fluid {
    .modal-dialog {
        margin-top: 0;
        margin-bottom: 0;
    }
    .modal-content {
        border-radius: 0;
    }
}

.achievement-modal .modal-dialog .modal-content {
    background-color: #1b998b;
}

.order-already-scheduled-modal .modal-dialog .modal-content {
    background-color: #f5b759;
}

.order-already-scheduled-modal .modal-dialog .modal-content {
    background-color: #FA5252;
}