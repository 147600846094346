.p-title {
  font-size: 2rem;
}

.ups-btn {
  height: 55.89px;
  border-radius: 3.99px;
}

.filter-flex {
  display: flex;
}

.pi-column {
  flex: 0 0 25%;
  max-width: 25%;
}

.filter-div {
  width: 371.24px;
  height: 78.09px;
}

@media screen and (max-width: 480px) {
  .p-title {
    font-size: 1.5rem;
  }

  .hi-fsize {
    font-size: 12px;
    gap: 15px;
  }

  .btn {
    font-size: 12px;
  }

  .ups-btn {
    height: 35px;
    border-radius: 3.99px;
  }

  .filter-flex {
    display: flex;
    flex-direction: column;
  }
  .pi-column {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .filter-div {
    width: 280px;
    height: 78.09px;
  }
}
